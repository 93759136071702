import React from 'react'
import styles from './LinkSection.module.scss'
import { LongArrowRight } from '@src/assets'
import cn from 'classnames'
import { Link } from '@kogk/gatsby-theme-base'
import {
  Row,
  Container,
  FancyShow
} from '@kogk/common'

export default ({ links }) => {
  return (
    <FancyShow>
      <Container className={styles.container}>
        <Row>
          {links && links.length > 0 && links.map(({
            link,
            title
          }, key) => {
            return (
              <Link
                className={cn(
                  styles.link,
                  'col-md-4 link link--big white-text'
                )}
                to={link}
                key={key}
              >
                <div
                  className={styles.col}
                >
                  {title}
                  <LongArrowRight />
                </div>
              </Link>
            )
          })}
        </Row>
      </Container>
    </FancyShow>
  )
}
