import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/drone/src/node_modules/@kogk/gatsby-plugin-ui-lib/dist/src/components/DefaultLayout/DefaultLayout.js";
import LinkSection from './LinkSection';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`LinkSection`}</h1>
    <p>{`// todo: knob stuff`}</p>
    <LinkSection mdxType="LinkSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      